#freetrial-button {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.11px;
  text-align: center;
  background-color: #0677d8;
  border-color: #0677d8;
  border-radius: 5px;
}

#login-button {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  color: #ffffff;
  border-radius: 5px;
}

.navbar-trans {
  background: linear-gradient(180deg, rgba(46, 48, 56, 1) 0%, rgba(46, 48, 56, 0) 100%);
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

#profile-button {
  padding: 0.5em;
  background-color: #0677d8;
  color: #ffffff;
  border-radius: 50%;
  min-height: 42px;
  min-width: 42px;
  letter-spacing: -1px;
}

@media screen and (max-width: 320px) {
  #freetrial-button {
    font-size: 13px;
  }
}

#search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background: url(../../assets/icons/close-button.svg) no-repeat;
  width: 16px;
  height: 16px;
}
