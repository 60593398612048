.video-popover {
  border: none !important;
  background-color: initial;
  z-index: 101;
}
.video-popover .arrow {
  display: none;
}
em {
  background-color: #ff6600;
  font-style: normal;
}
