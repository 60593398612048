@media screen and (max-width: 576px) {
  .reactour__helper.helper {
    width: 95%;
    min-height: 230px;
  }
  .videotutorial .cme-title {
    font-size: 16px;
  }
  .videotutorial .name {
    margin-top: 9px;
    margin-bottom: 11px;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .videotutorial .icon {
    width: 41px;
    height: 41px;
  }
  .videotutorial .cme-premium {
    font-size: 14px;
    width: 161px;
    height: 33px;
    margin-top: 14px;
  }
  .videotutorial p {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.tutorial .note {
  background-color: #e04463;
  z-index: -1;
}
.tutorial .file {
  background-color: #ff6800;
  z-index: -2;
}
.tutorial .multiple-icons {
  margin-left: -30px;
}
.tutorial .fa-sticky-note {
  width: 14px;
}
.tutorial .fa-file-alt {
  width: 12px;
}
