#conference_filter label {
  font-size: 13px;
  padding-top: 11px;
}

#conference_filter .custom-switch label {
  padding-top: 3px;
}

#conference_filter .custom-switch {
  padding-top: 8px;
}
