.course-popover .arrow {
  display: none;
}
.course-popover {
  border: none !important;
  background-color: initial;
  cursor: pointer;
  z-index: 100;
}
.course-popover .a_courseTileBookmark {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%) !important;
}
