.a_loginGoogleButton {
  align-items: center;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  height: 40px;
  width: 288px;
  border: none;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
}
.a_loginFacebookButton {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 15px !important;
  align-items: center;
  color: #fff;
  padding: 2px 5px;
  box-sizing: border-box;
  height: 48px;
  width: 300px;
  border: 2px solid #f9f9f9;
  border-radius: 8px;
  background: none;
}
.a_loginEBSCOButton {
  align-items: center;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  height: 40px;
  width: 288px;
  border: none;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
}

.a_loginEBSCOButton a,
.a_loginEBSCOButton a:hover {
  color: #fff;
  text-decoration: none !important;
}

.a_loginForgotPassword {
  color: #30a0ff;
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.a_loginStartFreeTrial {
  color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 20px;
  text-align: center;
}
.a_loginStartFreeTrial a {
  color: #008aff;
}

.login-modal {
  background-color: #2e3038;
}

.login-page {
  background-color: #181a21;
}

.loginForm {
  max-width: 300px;
  width: 100%;
  margin: auto;
}
.loginForm .form-control {
  height: 40px;
  max-width: 300px;
  margin: auto;
}

.text-center {
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
}

button.link-style {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  color: blue; /* or whatever color matches your design */
}