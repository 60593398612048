.a_spcialist-popover .popover-body {
  padding: 0;
  border-radius: 8px;
  color: #ffffff;
}
.a_spcialist-popover .arrow {
  display: none;
}
.a_spcialist-popover {
  bottom: -326px !important;
  left: -31px !important;
  border: none !important;
  z-index: 101;
}
