.a_orgTile {
  border: none !important;
  border-radius: 0;
  cursor: pointer;
  width: 160px;
  height: 160px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}
.org-popover .popover-body {
  padding: 0;
  border-radius: 8px;
}
.org-popover .arrow {
  display: none;
}
.org-popover {
  bottom: -254px !important;
  left: 0px !important;
  border: none !important;
  z-index: 100;
}
.a_orgImg {
  border-radius: 0;
  width: 100%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}
.follow-text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.9px;
  padding: 4px 7.5px;
  color: #ffffff;
  width: 80px;
}
