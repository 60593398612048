.a_ProfileConnectedToGoogle,
.a_profileConnectGoogleButton {
  background-color: rgb(255, 255, 255);
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  width: 207px;
  height: 43px;
}
.a_ProfileConnectedToFacebook,
.a_profileConnectFacebookButton {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  font-size: calc(0.27548vw + 12.71074px);
  width: 207px;
  height: 43px;
}
button:focus {
  outline: 0;
}
