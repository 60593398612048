.reactour__helper.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #ffffff;
  background-color: #4e505a;
  max-width: 549px;
  width: 549px;
  height: 273px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 30px;
}
@media screen and (max-width: 576px) {
  .reactour__helper.helper {
    width: 85%;
  }
}
.reactour__helper.helper button {
  color: #dcdde1;
}
.reactour__helper.helper button:hover {
  color: #252730;
}
.tutorial .name {
  margin-bottom: 22px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.33px;
  line-height: 21px;
}
.tutorial .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.tutorial .cme-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tutorial .cme-premium {
  height: 36px;
  width: 180px;
  background-color: rgba(46, 48, 56, 0.5);
  border-radius: 8px;
  text-align: center;
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 36px;
  font-weight: normal;
}
.tutorial .browse {
  background-color: #e04463;
}
.tutorial .course {
  background-color: #35afcb;
}
.tutorial .bookmark {
  background-color: #ff6800;
}
.tutorial .cme {
  background-color: #35cb95;
}
.tutorial .playlist {
  background-color: #008aff;
}
.tutorial .search {
  background-color: #e04463;
}
.tutorial .learning {
  background-color: #ff6800;
}
.tutorial .fa-video {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
}
.tutorial .fa-books-medical-solid {
  width: 1em !important;
}
.tutorial .fa-bookmark-solid {
  width: 13px !important;
}
.tutorial .fa-cme-1 {
  width: 32px !important;
  height: 32px;
}
.tutorial .fa-list-ul-solid {
  width: 17px !important;
}
.tutorial h3 {
  text-align: center;
  line-height: 46px;
  font-weight: bold;
  letter-spacing: -0.62px;
}
.tutorial p {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #dcdde1;
}
[data-tour-elem='controls'] {
  justify-content: center;
}
#___reactour > div:first-child {
  opacity: 0.5;
  color: black;
}
